import { jwtDecode } from "jwt-decode";
const storage = localStorage;

export function setItem(key, value) {
  storage.setItem(key, value);
}

export function getItem(key, default_val = "") {
  try {
    return storage.getItem(key);
  } catch (e) {
    console.error(e.toString());
  }
  return default_val;
}

export function clearCache() {
  storage.clear();
}

export const decodeToken = (token) => {
  if(token){
    let decodedToken = jwtDecode(token || '');
    setItem("userName", decodedToken?.name);
    setItem("mobileNumber",decodedToken?.mobile);
    setItem("products", JSON.stringify(decodedToken?.product));
  }
}