const CustomTextField = ({
  type,
  label,
  placeholder,
  onChangeHandler,
  value,
  name,
  mandatory,
  handleBlur,
  onKeyDown,
  readonly,
  disabled,
}) => {
  return (
    <div className="flex flex-col mt-[10px]">
      <label className="text-sm mb-1 text-primaryColor">
        {label}
        {mandatory && (
          <span className="text-red-600 text-base font-normal">*</span>
        )}
      </label>
      <input
        className="border border-[#E3E5E9] p-2 rounded-lg my-input bg-white text-black"
        type={type}
        placeholder={placeholder}
        onChange={onChangeHandler}
        value={value}
        name={name}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        readOnly={readonly}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomTextField;
