import { useNavigate } from "react-router";
import { useEffect } from "react";
import Loader from "./Common/Loader";
import { getItem } from "../utils/storage";

const AuthenticatedLayout = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = getItem("isLoggedIn");

  useEffect(() => {
    if (isLoggedIn === 'false' || !isLoggedIn || isLoggedIn === 'null') {
      navigate("/");
    }
  }, [isLoggedIn,navigate]);

  return (
    <div>
      <Loader isLoading={false} />
      <div className="flex h-screen bg-gray-100">
        {/* <SidebarV1 /> */}
        <div className="flex-1 flex flex-col overflow-x-hidden">
          {/* <Header /> */}
          <div className={`flex-1 flex flex-col bg-[#F9FBFF]`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayout;
