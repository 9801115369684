import React from 'react'
import { clearCache } from '../../utils/storage';
import { useNavigate } from 'react-router-dom';
import { CambriLogo } from '../../assets';

const Header = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        clearCache();
        navigate("/");
      };
  return (
    <div className="w-full shadow-md">
      <div className='flex justify-between w-[95%] mx-auto py-3'>
        <div className="flex items-center space-x-3 cursor-default">
          <img src={CambriLogo} alt="logo" className="w-10 h-10" />
          <p className="text-2xl font-medium">Cambri</p>
        </div>
        <button className="font-medium" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Header