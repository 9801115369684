import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeToken, setItem } from "../utils/storage";
import CustomButton from "./Common/Button";
import { toast } from "react-toastify";
import { CambriLogo } from "../assets";
import axiosInstance from "../utils/apiInterceptor";

let currentIndex = 0;
function Otp({ email }) {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const isOtpIncomplete = otp.some((digit) => digit === "");
  const inputRef = useRef(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onChangeHandler = (event) => {
    const value = event.target.value;
    const isNumeric = /^\d*$/.test(value);
    if (!isNumeric) {
      return;
    }
    const newOtp = [...otp];
    newOtp[currentIndex] = value.substring(value.length - 1);
    setOtp(newOtp);
    if (!value) {
      setActiveOTPIndex(currentIndex - 1);
    } else {
      setActiveOTPIndex(currentIndex + 1);
    }
  };

  const keyDownHandler = (event, index) => {
    currentIndex = index;
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      if (newOtp[currentIndex] !== "") {
        newOtp[currentIndex] = "";
        setOtp(newOtp);
        setActiveOTPIndex(currentIndex);
      } else {
        newOtp[currentIndex] = "";
        setOtp(newOtp);
        setActiveOTPIndex(currentIndex - 1);
      }
    }
  };


  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  const handleVerifyOtp = async () => {
    setIsLoading(true);

    const payload = {
      emailId: email,
      otp: otp.join(""),
    };
    let response;
    try{
      
      response = await axiosInstance.post("/auth/verifyotp", payload);
      
      if(response.status === 200){ 
        setItem("isLoggedIn", "true");
        setItem("token", response?.data?.token || '');
        decodeToken(response?.data?.token)
        response?.data?.isnewuser ? navigate("/details") : navigate('/dashboard');
        setIsLoading(false);
      }

    }catch(err){
      setIsLoading(false);
      toast.error("Failed to verify OTP");
      console.error("Error:", err);
    }
   
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      handleVerifyOtp();
    }
  };

  return (
    <div className="bg-lightPrimary w-full h-screen flex flex-col justify-center items-start relative">
      <div className="flex flex-col items-center justify-center h-full w-full z-50">
        <div className="flex justify-center mt-8 flex-row gap-8 w-full">
          <div className="w-[90%] md:w-[50%] lg:w-[35%] flex items-center flex-col gap-4 shadow-md px-16 py-8 rounded-xl bg-white">
            <img src={CambriLogo} alt="logo" className="w-24 h-24" />
            <p className="text-[#3C424C] text-2xl font-medium">OTP</p>
            <div className="flex flex-row items-center">
              <div className="text-[#3C424C] w-full flex flex-wrap items-center text-sm font-avenir font-medium justify-center mb-2">
                <p>Enter the OTP sent to</p>
                <div className="flex items-center justify-center">
                  <p className="font-bold ml-1">{email}</p>
                  <button
                    onClick={() => {
                      navigate("/");
                    }}
                    className="flex items-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.2069 4.5861L11.4144 1.79297C11.3215 1.70009 11.2113 1.62641 11.0899 1.57614C10.9686 1.52587 10.8385 1.5 10.7072 1.5C10.5759 1.5 10.4458 1.52587 10.3245 1.57614C10.2031 1.62641 10.0929 1.70009 10 1.79297L2.29313 9.50047C2.19987 9.593 2.12593 9.70313 2.0756 9.82448C2.02528 9.94584 1.99959 10.076 2.00001 10.2073V13.0005C2.00001 13.2657 2.10536 13.52 2.2929 13.7076C2.48043 13.8951 2.73479 14.0005 3.00001 14.0005H5.79313C5.9245 14.0009 6.05464 13.9752 6.17599 13.9249C6.29735 13.8746 6.40748 13.8006 6.50001 13.7073L14.2069 6.00047C14.2998 5.90761 14.3734 5.79736 14.4237 5.67602C14.474 5.55468 14.4999 5.42463 14.4999 5.29329C14.4999 5.16195 14.474 5.03189 14.4237 4.91055C14.3734 4.78921 14.2998 4.67896 14.2069 4.5861ZM5.79313 13.0005H3.00001V10.2073L8.50001 4.70735L11.2931 7.50047L5.79313 13.0005ZM12 6.79297L9.20688 4.00047L10.7069 2.50047L13.5 5.29297L12 6.79297Z"
                        fill="#A033FF"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              {otp.map((_, index) => (
                <React.Fragment key={""}>
                  <input
                    ref={index === activeOTPIndex ? inputRef : null}
                    type="tel"
                    className="w-8 h-8 m-2 rounded-md bg-transparent outline-none text-center font-semibold text-xl border border-[#E3E5E9] mb-6"
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                    onKeyDown={(event) => {
                      keyDownHandler(event, index);
                      onKeyDownHandler(event);
                    }}
                    value={otp[index]}
                  />
                </React.Fragment>
              ))}
            </div>

            <CustomButton
              disabled={isOtpIncomplete}
              isLoading={isLoading}
              color="text-[#FFFFFF]"
              bg="bg-[#A033FF]"
              width="w-full"
              name="REGISTER"
              font="font-extrabold"
              onClickHandler={handleVerifyOtp}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Otp;
