import React, { useState } from "react";
import CustomButton from "./Common/Button";
import CustomTextField from "./Common/TextField";
import { CambriLogo } from "../assets";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { decodeToken, setItem } from "../utils/storage";
import axiosInstance from "../utils/apiInterceptor";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobileNumber: "",
  });
  const [mobileError, setMobileError] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleMobileChange = (number) => {
    const newData = { ...userDetails };
    newData.mobileNumber = number ?? "";
    setUserDetails(newData);
    if (!number) {
      setMobileError(false);
    } else {
      const isValid = isValidPhoneNumber(number);
      setMobileError(!isValid);
    }
  };

  const handleNameChange = (e) => {
   const { value } = e.target;
   let newData = {...userDetails};
   newData.name = value;
   setUserDetails(newData);
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    setItem("userName", userDetails.name);
    setItem("mobile",userDetails.mobileNumber);

    const payload ={
      name : userDetails.name,
      mobileNumber: userDetails.mobileNumber
    }

    let response;
    try {
      response = await axiosInstance.post("/user/update", payload);
      if (response.status === 200) {
        setIsLoading(false);
        setItem("token", response?.data?.token || '');
        decodeToken(response?.data?.token);
        navigate("/dashboard");
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data || "Failed to update the details");
      console.error("Error:", err);
    }
  }

  const getIsDisabled = () => {
    return userDetails.name.trim() === "" || userDetails.mobileNumber.trim() === "" || mobileError
  }

  return (
    <div className="bg-lightPrimary">
      <div className="flex flex-col items-center justify-center h-screen w-full z-50 ">
        <div className="flex justify-center mt-8 flex-row gap-8 items-center w-full">
          <div className="w-[90%] md:w-[50%] lg:w-[35%] flex items-center flex-col gap-7 shadow-md px-16 py-8 bg-white rounded-xl">
            <img src={CambriLogo} alt="logo" className="w-24 h-24" />
            <p className="text-[#3C424C] text-2xl font-medium">
              Kindly provide your details
            </p>
            <div className="w-full">
              <CustomTextField
                value={userDetails.name}
                type="text"
                placeholder="Name"
                onChangeHandler={(e) => handleNameChange(e)}
                label=""
                mandatory={false}
              />
              <div className="my-5">
                <PhoneInput
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  international
                  countryCallingCodeEditable={false}
                  value={userDetails.mobileNumber}
                  onChange={(num) => handleMobileChange(num)}
                  className="p-2 border border-gray-300 rounded-md shadow-sm"
                />
                {mobileError && (
                  <p className="text-xs text-red-500 text-center">
                    Please enter a valid mobile number.
                  </p>
                )}
              </div>
            </div>
            <CustomButton
              disabled={getIsDisabled()}
              isLoading={isLoading}
              color="text-[#FFFFFF]"
              bg="bg-[#A033FF]"
              name="Next"
              font="font-extrabold"
              width="w-full"
              onClickHandler={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
