
const get_profile = () => {
    const host = window.location.host;
    if (host === 'localhost:3000') return 'dev';
    if (host === 'ielts-staging.web.app') return 'staging';
    // Remaining host need to be added here going forth as per env setup
    return 'unknown';
  };
  
  let DOMAIN = 'https://sso.api.cambri.ai';

  const profile = get_profile();
  
  
  switch (profile) {
    case 'dev':
      case 'staging':
      DOMAIN = 'https://sso.api.cambri.ai';
      break;
    default:
      DOMAIN = 'https://sso.api.cambri.ai';
      break;
  }
  
  export const be_url = DOMAIN;
  
  export const commonHeaders = {
    'Content-Type': 'application/json',
  };

  export const DEFAULT_API_CONFIG = `${be_url}/api`;

  