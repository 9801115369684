import React, { useEffect, useState } from "react";
import CustomButton from "./Common/Button";
import { getItem } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "./Common/Header";
import {CambriLogo, LockIcon} from "../assets/index";
import axiosInstance from "../utils/apiInterceptor";

const Dashboard = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [module, setModule] = useState("");
  const [products,setProducts] = useState([]);
  const [error,setError] = useState(false);

  const getProductsInfo = async () => {
    setIsLoading(true);
    let response;
    try{
      response = await axiosInstance.get('/gre/module/getAll');
      if(response.status === 200){
        setIsLoading(false);
        setProducts(response.data);
        setError(false);
      }else{
        setIsLoading(false);
        setError(true);
      }
    }catch(err){
      console.error(err);
      setIsLoading(false);
      setError(true);
    }
  }

  const handleOnClick = (isAvailable,productUrl) => {
    if (isAvailable) {
      window.open(`${productUrl}${getItem('token')}`, "_blank", "noopener,noreferrer");
    }
  }


  const renderContent = () => {
    if(isLoading){
      return (
        <div className="flex items-center justify-center h-[90vh]">
          <div className="loader"></div>
        </div>
      )
    }

    if(error){
      return (
        <div className="flex justify-center items-center h-[90vh] text-primary">
          <p className="text-3xl">There was an error. Please try refreshing the page.</p>
        </div>
      )
    }

    if(products.length === 0) {
      return (
        <div className="flex items-center justify-center h-[90vh] text-primary">
          <p className="text-3xl">There are no products available at the moment. Please contact our team.</p>
        </div>
      ) 
    }else{
      return (
        <div className="mt-20 flex gap-5">
          {products.map((product) => (
            <button
              key={product?._id}
              className={`py-7 px-6 border-2  rounded-lg shadow-md relative ${
                product?.isProductAvailable
                  ? "cursor-pointer"
                  : "cursor-not-allowed bg-gray-300 text-gray-500 opacity-60"
              }`}
              onClick={() => handleOnClick(product?.isProductAvailable,product?.appUrl)}
              disabled={!product?.isProductAvailable}
            >
              {!product?.isProductAvailable && (
                <img
                  src={LockIcon}
                  alt="lock-icon"
                  className="absolute top-1 right-1 w-5 h-5"
                />
              )}
              <img
                src={
                  product?.logoUrl.trim() !== "" ? product?.logoUrl : CambriLogo
                }
                alt="logo"
                className="w-24 h-24 mx-auto"
              />
              <p className="text-2xl mt-5">{product?.name}</p>
            </button>
          ))}
        </div>
      );

    }
  }

  useEffect(() => {
    getProductsInfo();
  }, []);
  console.log(products);

  return (
    <div>
      <Header/>
      <div className="text-center  flex gap-4 justify-center">       
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;
