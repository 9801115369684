import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./components/Routing";

function App() {
  return (
    <div>
       <Router>
          <Routing />
        </Router>
    </div>
  );
}

export default App;
