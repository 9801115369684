import React, { useEffect, useState } from "react";
import CustomTextField from "./Common/TextField";
import { useNavigate } from "react-router-dom";
import CustomButton from "./Common/Button";
import { toast } from "react-toastify";
import {CambriLogo} from "../assets/index";
import axiosInstance from "../utils/apiInterceptor";
import { clearCache, setItem } from "../utils/storage";

const Login = ({email,setEmail}) => {
  
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  const handleGetOTP = async () => {
    setIsLoading(true);
    setItem("userEmail", email);

    const payload = {
      emailId: email,
    };

    let response;
    try{
      response = await axiosInstance.post("/auth/sendotp", payload);
      if(response.status === 200){
        setIsLoading(false);
        navigate("/otp");
      }
    }catch(err){
      setIsLoading(false);
      toast.error("Failed to send OTP");
      console.error("Error:", err);
    }
  };

  const handleEmail = (evt) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setEmail(evt?.target?.value?.toLowerCase());
    if (emailPattern.test(evt?.target?.value?.toLowerCase()) || evt.target.value.trim() === "") {
      setEmailError(false);
    }else{
      setEmailError(true);
    }
  }


  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      handleGetOTP();
    }
  };

  useEffect(()=>{
    clearCache()
  },[])

  return (
    <div className="bg-lightPrimary">
      <div className="flex flex-col items-center justify-center h-screen w-full z-50 ">
        <div className="flex justify-center mt-8 flex-row gap-8 items-center w-full">
          <div className="w-[90%] md:w-[50%] lg:w-[35%] flex items-center flex-col gap-7 shadow-md px-16 py-8 bg-white rounded-xl">
            <img src={CambriLogo} alt="logo" className="w-24 h-24" />
            <p className="text-[#3C424C] text-2xl font-medium">Login</p>
            <div className="w-full">
              <CustomTextField
                value={email}
                type="text"
                placeholder="Email"
                onChangeHandler={(e) => handleEmail(e)}
                label=""
                mandatory={false}
                onKeyDown={onKeyDownHandler}
              />
              {emailError && (
                <p className="text-red-600 text-xs text-center font-normal">
                  please enter valid email
                </p>
              )}
            </div>
            <CustomButton
              disabled={!email|| emailError || isLoading}
              isLoading={isLoading}
              color="text-[#FFFFFF]"
              bg="bg-[#A033FF]"
              name="GET OTP"
              font="font-extrabold"
              width="w-full"
              onClickHandler={handleGetOTP}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
