import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Otp from "./Otp";
import Login from "./Login";
import Dashboard from "./Dashboard";
import AuthenticatedLayout from "./AuthenticatedLayout";
import { getItem } from "../utils/storage";
import UserDetails from "./UserDetails";

const Routing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email,setEmail] = useState("");
  const isLoggedIn = getItem('isLoggedIn')

  useEffect(() => {
    const getRedirectPath = () => {
      if (isLoggedIn === 'true') {
        return "/dashboard";
      }
      return null;
    };

    const redirectPath = getRedirectPath();
    if (
      redirectPath &&
      ["/", "/login", "/otp", `/verifyUser`].includes(location.pathname)
    ) {
      navigate(redirectPath);
    }
  }, [isLoggedIn, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login email={email} setEmail={setEmail} />} />
      <Route path="/otp" element={<Otp email={email} />} />
      <Route path="*" element={<p> Not Found </p>} />
      <Route
        path="/dashboard"
        element={
          <AuthenticatedLayout>
            <Dashboard />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/details"
        element={
          <AuthenticatedLayout>
            <UserDetails />
          </AuthenticatedLayout>
        }
      />
    </Routes>
  );
};

export default Routing;
